const INFO = {
	main: {
		title: "PersonalWebsite by Nick Grifasi",
		name: "Nick Grifasi",
		email: "grifasinick@gmail.com",
	},

	socials: {
		github: "https://github.com/nickgrifasi/",
		linkedin: "https://linkedin.com/in/nick-grifasi",
		instagram: "https://www.instagram.com/nickgrifasi/?hl=en",
	},

	homepage: {
		title: "Hello! My name is Nick Grifasi and I am currently looking for a software development job",
		description:
		"I am a recent graduate from Virginia Tech with a Bachelors of Science in computer science. I am looking for a full-time position in software development. This website acts as an extension of my resume including contact information, my projects, and work history. Thank you and please go to the contacts tab to reach out.",
	},

	projects: [
		{
			title: "Customizable Bash Shell",
			description:
				"Developed a custom job control shell in C. The shell has the ability to start foreground and background jobs and built-in commands. Supports piping, I/O, Redirection, and managing the terminal state.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/c/c.png",
			linkText: "View Project",
			link: "https://gitfront.io/r/user-7595445/njtZ2ecojENn/customBashShell/",
		},

		{
			title: "PRQuadTree",
			description:
				"Implementation of a Point-Region quadtree data structure in Java. Each node has either exactly four children or is a leaf. Project comes with junit tests and a text parser to help with testing.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/java/java.png",
			linkText: "View Project",
			link: "https://gitfront.io/r/user-7595445/QV2PMtL9ys4C/PRQuadTree/",
		},

		{
			title: "Visualizing eTextbook Study Sessions",
			description:
				"Capstone Project to design a system that created visualizations of student interaction data gathered from the OpenDSA textbook.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/python/python.png",
			linkText: "View Project",
			link: "https://git.cs.vt.edu/nickgrifasi/cs_4624_project",
			linkText2: "View Project Report",
			link2: "https://vtechworks.lib.vt.edu/handle/10919/115008"
		},

		{
			title: "NFL Big Data Bowl Challenge 2021",
			description:
				"Our submission to the NFL Big Data Bowl 2021, in which our goal is to use data science to better understand the schemes and players that make for a successful defense against passing plays. Completed with the Sports Data Analytics Club at Virginia Tech.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/python/python.png",
			linkText: "View Project",
			link: "https://www.kaggle.com/code/nickgrifasi/fork-of-sdac-vt-big-data-bowl-challenge/edit",
		},

		{
			title: "SkipList",
			description:
				"Implementation of a SkipList data structure in Java. The SkipList is designed to overcome the basic limitations of array or linked based lists. Each search/update operation requires linear time. It is a probabilistic data structure as it is pseudo-random. Project comes with junit tests and a text parser to help with testing.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/java/java.png",
			linkText: "View Project",
			link: "https://gitfront.io/r/user-7595445/pEHmH5Mfj2Ex/skipList/",
		},
	],
};

export default INFO;
